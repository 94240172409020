import React, { useRef,useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import '../../MainStyle.css';

function Privilege(){
    const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "Privilege",
        isLoadingShow: false,
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white">
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <LoadingDialog intentData={pageConst}/>

          <div className="col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15 bg-white">

               <div className='col-view pd-5-15 mg-t-15'>
                <p>3 Level invitess, each user will receive commission.</p>
                <div className='w-100 pd-15 mg-t-10 br-8 br-a-l-blue'>
                  <img className='w-100' src={require("../icons/3_level_income.jpg")} />
                </div>

                <p className='mg-t-15'>Reward ₹250 for each Registered user</p>
                <div className='w-100 pd-15 mg-t-10 br-8 br-a-l-blue'>
                  <img className='w-100' src={require("../icons/refer_banner_1.png")} />
                  <img className='w-100' src={require("../icons/refer_banner_2.jpg")} />
                </div>
               </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default Privilege;