import React, { useRef,useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import LoadingDialog from '../dialogs/LoadingDialog';
import { API_ACCESS_URL,generateAuthToken } from '../modals/Constants';
import { getStorage } from '../modals/Storage';

function Transactions(){
    const navigate = useNavigate();
    const listViewRef = useRef();
    const [pageConst, setConstants] = useState({
      pageTitle: "Transactions",
      isLoadingShow: false,
      pageCount: 1,
      recordList: [],
    });

    const topBarClickAction = (data) =>{
        if(data=="multiBtn1"){
          navigate('/withdraw', { replace: false });
        }
    }

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const onScroll = () => {
      if (listViewRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listViewRef.current;
        const isNearBottom = scrollTop + clientHeight >= scrollHeight;

        if (isNearBottom) {
          let newPageVal = pageConst.pageCount + 1;
          setConstants(previousState => {
            return { ...previousState, pageCount: newPageVal }
          });
        }
      }
    }

    const updateRecordList = (data) => {
      let tempData = [];

      if(pageConst.recordList.length > 0){
        tempData = pageConst.recordList;
      }

        for (let i = 0; i < data.length; i++) {          
            tempData.push(
              <div key={i} className="row-view sb-view pd-10-15 mg-t-15">
                <div className='row-view'>
                 <img className='h-w-32' src={require('../icons/transaction_icon.png')} />

                 <div className='col-view mg-l-20'>
                  <span className='ft-sz-17'>{data[i]['t_title']}</span>
                  <span className='ft-sz-12 mg-t-5'>{data[i]['t_time_stamp']}</span>
                 </div>
                </div>
                <span className='ft-sz-17 cl-green'>₹{data[i]['t_amount']}</span>
              </div>)
        };

        setConstants(previousState => {
            return { ...previousState, recordList: tempData }
        });
    }

    function getTransactions(pageCount){
        const requestAPI = async (url) => {
          try {
            const res = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Route': 'route-transactions',
                'AuthToken': generateAuthToken(Number(getStorage("uid"))),
              }
            });

            const data = await res.json();
            updateLoadingStatus(false);

            if(data.status_code="success"){
              updateRecordList(data.data); 
            }

          } catch (error) {
            updateLoadingStatus(false);
          }

        }

        updateLoadingStatus(true);
        requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid")+"&PAGE_NUM="+pageCount);
    }
  
    useEffect(() => {
      getTransactions(pageConst.pageCount);
      const listInnerElement = listViewRef.current;

      if (listInnerElement) {
        listInnerElement.addEventListener("scroll", onScroll);

        // Clean-up
        return () => {
          listInnerElement.removeEventListener("scroll", onScroll);
        };
      }
    }, [pageConst.pageCount]);

    return (
      <div className="v-center">
        <div className="h-100vh pr-v res-wth ovf-scrl-y hide-sb bg-white" ref={listViewRef}>
          <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="" updateState={topBarClickAction}/>
          <LoadingDialog intentData={pageConst}/>

          <div className="col-view mg-t-45">

            <div className="col-view br-right-t br-left-t mg-b-15 bg-white">

               <div className='col-view pd-5-15'>
                  <div className="col-view">
                  {pageConst.recordList}
                  </div>
                </div>

            </div>

          </div>
        </div>
      </div>
    );
}

export default Transactions;