import React from 'react'

const winDialog = ({intentData,updateState}) => {

    return (
        <div className={`ps-fx h-100vh res-wth z-i--100 bg-l-black v-center ${intentData.winDialogShow ? 'activeDialog' : ''}`} onClick={()=>{updateState(false)}}>
            <div className='ps-rl w-85 bg-white br-7'>
                <img className="ps-ab ps-lf-50 t-form-x w-350-p ps-tp--170" src={require(intentData.constWinDialog ? '../icons/win_dialog_icon.png' : '../icons/lose_dialog_icon.png')} />
                
                <div className='col-view pd-15 v-center'>

                    <div className='w-100 row-view sb-view ft-sz-18 mg-t-45'>
                        <span>Period:</span>
                        <span>{intentData.constPeriodId}</span>
                    </div>

                    <div className='w-100 col-view v-center pd-10 br-5 mg-t-15 bg-l-blue br-a-l-blue'>
                      <div className='row-view sb-view ft-sz-18 w-100'>
                        <span>Result:</span>
                        {intentData.constWinResultHtml}
                      </div>

                      <div className='row-view sb-view ft-sz-18 w-100 mg-t-5'>
                        <span>Amount:</span>
                        <span className={`ft-sz-25 ${intentData.constWinDialog ? 'cl-green' : 'cl-red'}`}>{intentData.constWinDialog ? '+₹'+intentData.constWinAmount : '-₹'+intentData.constWinAmount}</span>
                      </div>

                      <span className='ft-sz-13 mg-t-15'>{intentData.constWinDialog ? 'Remember winning amount will be credited to your wallet within 1-2 minutes.' : 'Better Luck Next Time.'}</span>
                    </div>

                    <div className='w-100 h-50-p ft-sz-20 pd-10 br-10 cl-white v-center mg-t-15 bg-cyan-blue bx-cyan-blue'>
                      <span>Continue</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default winDialog;