import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TopBar from '../other-components/TopBar';
import ToastDialog from '../dialogs/ToastDialog';
import '../../MainStyle.css';
import { API_ACCESS_URL,redirectTo } from '../modals/Constants';
import { getStorage } from '../modals/Storage';
import LoadingDialog from '../dialogs/LoadingDialog';

function DailyCheckIn(){
  const navigate = useNavigate();
    const [pageConst, setConstants] = useState({
        pageTitle: "Check In",
        checkInDays: 0,
        isLoadingShow: false,
        toastDialogShow: false,
        toastMessage: "",
    });

    const updateLoadingStatus = (data) => {
        setConstants(previousState => {
            return { ...previousState, isLoadingShow: data }
        });
    }

    const updateToastDialogState = (data,msg) => {
      setConstants(previousState => {
        return { ...previousState, toastDialogShow: data }
      });

      setConstants(previousState => {
        return { ...previousState, toastMessage: msg }
      });
    }

    const claimDailyCheckIn = () => {
      const requestAPI = async (url) => {
        try {
          const res = await fetch(url, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Route': 'route-claim-checkin',
              'AuthToken': getStorage("secret"),
            }
          });

          const data = await res.json();
          updateLoadingStatus(false);

          if(data.status_code=="authorization_error"){
            updateToastDialogState(true,"Authorization Error!");
          }else if(data.status_code=="no_premium"){
            updateToastDialogState(true,"Sorry! Please complete your first recharge!");
          }else if(data.status_code=="already_claimed"){
            updateToastDialogState(true,"Sorry! You've already Claimed!");
          }else if(data.status_code=="success"){
            updateToastDialogState(true,"Bonus Claimed!");
          }else if(data.status_code=="not_available"){
            updateToastDialogState(true,"Bonus Not Available!");
          }else{
            updateToastDialogState(true,"Something went wrong! Please try again!");
          }

        } catch (error) {
          updateLoadingStatus(false);
          updateToastDialogState(true,"There was a technical issue! Please try again!");
        }
      };

      if(pageConst.isLoadingShow==false){
        updateLoadingStatus(true);
        requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid"));
      }
    }

    const getCheckInDays = () => {
        const requestAPI = async (url) => {
          try {
            const res = await fetch(url, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Route': 'route-load-checkin',
                'AuthToken': getStorage("secret"),
              }
            });
  
            const data = await res.json();
            updateLoadingStatus(false);
  
            if(data.status_code=="success"){
                setConstants(previousState => {
                    return { ...previousState, checkInDays: data.checkin_days }
                });
            }
  
          } catch (error) {
            updateLoadingStatus(false);
            updateToastDialogState(true,"There was a technical issue! Please try again!");
          }
        };
  
        if(pageConst.isLoadingShow==false){
          updateLoadingStatus(true);
          requestAPI(API_ACCESS_URL+"?USER_ID="+getStorage("uid"));
        }
    }

    useEffect(() => {
      if(!getStorage("uid")){
        navigate('/login', { replace: true });
      }else{
        getCheckInDays();
      }
    }, []);

    return (
      <div className="v-center bg-black">
        <div className='h-100vh res-wth ovf-hidden app-bg'>
          <ToastDialog intentData={pageConst} updateState={updateToastDialogState} />
          <LoadingDialog intentData={pageConst}/>
          
          <div className="pr-v ovf-scrl-y hide-sb zoomAnimView">
            <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2=""/>

            <div className='col-view w-100 v-center pd-5-15 mg-b-15 mg-t-45'>

                <div className='w-100 ps-rl pd-15-0 mg-t-20 br-5 bg-white'>
                  <div className='w-100 g-v-4'>
                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 1 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 1</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+1</span>
                    </div>

                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 2 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 2</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+1</span>
                    </div>

                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 3 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 3</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+2</span>
                    </div>

                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 4 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 4</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+1</span>
                    </div>

                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 5 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 5</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+1</span>
                    </div>

                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 6 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 6</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+1</span>
                    </div>

                    <div className={`col-view a-center pd-15-0 ${pageConst.checkInDays >= 7 ? 'greys-100' : ''}`}>
                      <span className="ft-sz-17">Day 7</span>
                      <img className="h-40-p mg-t-5" src={require('../icons/coin_check_icon.png')} />
                      <span className="cl-grey ft-sz-23 mg-t-5">+3</span>
                    </div>

                    <div className="col-view v-center pd-15-0">
                      <img className="h-70-p" src={require('../icons/treasure_icon.png')} />
                    </div>

                  </div>

                  <div className='ps-ab ps-lf-50 ft-sz-20 pd-5-15 t-form-x br-20 cl-white bg-btn-active' onClick={() => claimDailyCheckIn()}>
                    Check In
                  </div>
                </div>

                <div className="col-view a-center pd-15-0 mg-t-20 mg-b-70">
                    <span className="txt-a-center cl-white ft-sz-17">Check in for 7 consecutive days to get treasure box, and receive mysterious prizes! .</span>
                    <img className="h-250-p mg-t-5" src={require('../icons/treasure_2_icon.png')} />
                </div>

            </div>

          </div>

        </div>
      </div>
    );
}

export default DailyCheckIn;