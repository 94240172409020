import React, { useCallback, useEffect, useState } from 'react'

function RandEveryoneOrderItem({periodId,randBetNum}){

    const getRandBool = () => {
        let returnVal = false;
  
        let status=Math.round(Math.random())
        if(status==1){
          returnVal = true;
        }
  
        return returnVal;
    }

    const getCapitalLetter = (data) => {
        let returnVal = "";
 
        if(data=="red"){
          returnVal = "R";
        }else if(data=="green"){
          returnVal = "G";
        }else if(data=="violet"){
          returnVal = "V";
        }else{
          returnVal = data;
        }
 
        return returnVal;  
    }

    const getColourCodeVal = (data) => {
        let returnVal = "";

        if(data=="1" || data=="3" || data=="7" || data=="9"){
            returnVal = "g";
        }else if(data=="2" || data=="4" || data=="6" || data=="8"){
            returnVal = "r";
        }else if(data=="0"){
            returnVal = "rv";
        }else if(data=="5"){
            returnVal = "gv";
        }else if(data=="red"){
          returnVal = "r";
        }else if(data=="green"){
          returnVal = "g";
        }else if(data=="violet"){
          returnVal = "v";
        }

        return returnVal;
    }

    const getRandomNum = (min, max, type) => {
        if(type=="multi"){
          return Math.round((Math.random()*(max-min)+min)/10)*10;
        }else{
          return Math.floor(Math.random() * (max - min) + min);
        }
    }

    const generateRandBetId = () =>{
        return getRandomNum(100,999, '');
    }

    const generateRandBetAmount = () => {
        let randBetAmnt = getRandomNum(10,2000, 'multi');
        if(getRandBool()){
          randBetAmnt = getRandomNum(10,300, 'multi');
        }
  
        return randBetAmnt;
      }

    return (
        
        <div className="row-view mg-t-15 sb-view">
          <span className='ft-sz-14'>{periodId}</span>
          <span className='ft-sz-14'>***{generateRandBetId()}</span>
          <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center ${getColourCodeVal(randBetNum)=="r" ? 'bg-red' : getColourCodeVal(randBetNum)=="g" ? 'bg-green' : getColourCodeVal(randBetNum)=="rv" ? 'bg-rv' : 'bg-gv'}`}>{getCapitalLetter(randBetNum)}</span>
          <span className="ft-sz-14 txt-a-end w-50-p">₹{generateRandBetAmount()}</span>
        </div>
    );
}

export default RandEveryoneOrderItem;